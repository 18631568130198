import React, { Suspense } from "react";
import { BrowserRouter as Router, NavLink } from "react-router-dom";
import { HashRouter } from "react-router-dom";
import AnimatedCursor from "react-animated-cursor";

const Hero = React.lazy(() => import("./pages/hero"));

const App = () => {
  return (
    <Suspense
      fallback={
        <div className="loading">
          <h1>LOADING...</h1>
        </div>
      }
    >
      <AnimatedCursor
        innerSize={12}
        outerSize={12}
        color="255, 255, 255"
        outerAlpha={0.2}
        innerScale={0.7}
        outerScale={5}
        hasBlendMode={true}
        outerStyle={{
          mixBlendMode: "exclusion",
        }}
        clickables={[
          "a",
          'input[type="text"]',
          'input[type="email"]',
          'input[type="number"]',
          'input[type="submit"]',
          'input[type="image"]',
          "label[for]",
          "select",
          "textarea",
          "button",
          ".link",
          ".cursor",
        ]}
      />
      <Router>
        <div>
          {/*  <img
          className="overlay"
          src="https://blog.spoongraphics.co.uk/wp-content/uploads/2012/05/22.jpg"
          alt=""
        /> */}
          <nav className="navigation">
            <NavLink exact activeClassName="active" className="link" to="/">
              Home
            </NavLink>
            <NavLink activeClassName="active" className="link" to="/contact">
              Contact
            </NavLink>
          </nav>
          <Hero />
        </div>
      </Router>
    </Suspense>
  );
};

export default App;
